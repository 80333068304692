exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-dashboard-js": () => import("./../../../src/pages/adminDashboard.js" /* webpackChunkName: "component---src-pages-admin-dashboard-js" */),
  "component---src-pages-admin-takeover-drive-js": () => import("./../../../src/pages/adminTakeoverDrive.js" /* webpackChunkName: "component---src-pages-admin-takeover-drive-js" */),
  "component---src-pages-bot-management-js": () => import("./../../../src/pages/bot-management.js" /* webpackChunkName: "component---src-pages-bot-management-js" */),
  "component---src-pages-create-match-js": () => import("./../../../src/pages/create-match.js" /* webpackChunkName: "component---src-pages-create-match-js" */),
  "component---src-pages-create-mission-js": () => import("./../../../src/pages/createMission.js" /* webpackChunkName: "component---src-pages-create-mission-js" */),
  "component---src-pages-create-quest-js": () => import("./../../../src/pages/createQuest.js" /* webpackChunkName: "component---src-pages-create-quest-js" */),
  "component---src-pages-daily-frequency-js": () => import("./../../../src/pages/daily_frequency.js" /* webpackChunkName: "component---src-pages-daily-frequency-js" */),
  "component---src-pages-drive-js": () => import("./../../../src/pages/drive.js" /* webpackChunkName: "component---src-pages-drive-js" */),
  "component---src-pages-epoch-rides-js": () => import("./../../../src/pages/epochRides.js" /* webpackChunkName: "component---src-pages-epoch-rides-js" */),
  "component---src-pages-frodo-point-activities-js": () => import("./../../../src/pages/frodo-point-activities.js" /* webpackChunkName: "component---src-pages-frodo-point-activities-js" */),
  "component---src-pages-frodo-point-js": () => import("./../../../src/pages/frodoPoint.js" /* webpackChunkName: "component---src-pages-frodo-point-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-js": () => import("./../../../src/pages/leaderboard.js" /* webpackChunkName: "component---src-pages-leaderboard-js" */),
  "component---src-pages-lp-refund-js": () => import("./../../../src/pages/lp-refund.js" /* webpackChunkName: "component---src-pages-lp-refund-js" */),
  "component---src-pages-mission-checkpoints-js": () => import("./../../../src/pages/missionCheckpoints.js" /* webpackChunkName: "component---src-pages-mission-checkpoints-js" */),
  "component---src-pages-penalties-js": () => import("./../../../src/pages/penalties.js" /* webpackChunkName: "component---src-pages-penalties-js" */),
  "component---src-pages-personal-bots-js": () => import("./../../../src/pages/personal-bots.js" /* webpackChunkName: "component---src-pages-personal-bots-js" */),
  "component---src-pages-publicspectate-js": () => import("./../../../src/pages/publicspectate.js" /* webpackChunkName: "component---src-pages-publicspectate-js" */),
  "component---src-pages-publicspectatemode-js": () => import("./../../../src/pages/publicspectatemode.js" /* webpackChunkName: "component---src-pages-publicspectatemode-js" */),
  "component---src-pages-remove-tournament-js": () => import("./../../../src/pages/removeTournament.js" /* webpackChunkName: "component---src-pages-remove-tournament-js" */),
  "component---src-pages-ride-review-js": () => import("./../../../src/pages/rideReview.js" /* webpackChunkName: "component---src-pages-ride-review-js" */),
  "component---src-pages-robotsfun-create-tournament-js": () => import("./../../../src/pages/robotsfun-create-tournament.js" /* webpackChunkName: "component---src-pages-robotsfun-create-tournament-js" */),
  "component---src-pages-robotsfun-edit-round-js": () => import("./../../../src/pages/robotsfun-edit-round.js" /* webpackChunkName: "component---src-pages-robotsfun-edit-round-js" */),
  "component---src-pages-robotsfun-edit-tournament-js": () => import("./../../../src/pages/robotsfun-edit-tournament.js" /* webpackChunkName: "component---src-pages-robotsfun-edit-tournament-js" */),
  "component---src-pages-robotsfun-list-tournaments-js": () => import("./../../../src/pages/robotsfun-list-tournaments.js" /* webpackChunkName: "component---src-pages-robotsfun-list-tournaments-js" */),
  "component---src-pages-robotsfun-tournaments-score-js": () => import("./../../../src/pages/robotsfun-tournaments-score.js" /* webpackChunkName: "component---src-pages-robotsfun-tournaments-score-js" */),
  "component---src-pages-share-with-sam-js": () => import("./../../../src/pages/shareWithSam.js" /* webpackChunkName: "component---src-pages-share-with-sam-js" */),
  "component---src-pages-spectate-dashboard-js": () => import("./../../../src/pages/spectateDashboard.js" /* webpackChunkName: "component---src-pages-spectate-dashboard-js" */),
  "component---src-pages-tournament-js": () => import("./../../../src/pages/tournament.js" /* webpackChunkName: "component---src-pages-tournament-js" */),
  "component---src-pages-ufb-bookings-js": () => import("./../../../src/pages/ufb-bookings.js" /* webpackChunkName: "component---src-pages-ufb-bookings-js" */),
  "component---src-pages-ufb-create-booking-js": () => import("./../../../src/pages/ufb-create-booking.js" /* webpackChunkName: "component---src-pages-ufb-create-booking-js" */),
  "component---src-pages-xp-form-js": () => import("./../../../src/pages/xpForm.js" /* webpackChunkName: "component---src-pages-xp-form-js" */)
}

